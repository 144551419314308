import React, { FunctionComponent } from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"

import Button from "../components/Button"
import Layout from "../components/Layout"
import OnScreen from "../components/OnScreen"
import SEO from "../components/SEO"
import { SuccessStory } from "../types"

interface Props {
  data: any
}

const Speaking: FunctionComponent<Props> = ({ data }) => {
  const stories = data.allContentfulSuccessStory.edges.map(
    (elem: any) => elem.node
  ) as SuccessStory[]

  function getTestimonials() {
    const order = ["Mark Hansen", "Mitzi Perdue", "Micki Meyer"]
    const testimonials = []

    for (let i = 0; i < order.length; i += 1) {
      for (let j = 0; j < stories.length; j += 1) {
        if (order[i] === stories[j].name) {
          const elem = stories[j]

          testimonials.push(
            <OnScreen
              className="bg-gray p-4 rounded-md shadow-md"
              classToAdd="slide-in-left"
              key={elem.id}
            >
              <div className="flex items-center mb-8">
                <div className="h-28 w-28">
                  <GatsbyImage
                    alt={elem.title}
                    className="rounded-full filter drop-shadow-lg"
                    image={elem.coverImage.gatsbyImageData}
                  />
                </div>

                <div className="flex-1 ml-8">
                  <p className="font-Orbitron mb-1 text-2xl">{elem.name}</p>

                  <p>{elem.title}</p>
                </div>
              </div>
              <p className="pb-4">{elem.testimonial.testimonial}</p>
            </OnScreen>
          )
        }
      }
    }

    return testimonials
  }

  return (
    <Layout>
      <SEO
        title="Book Will For Your Event"
        description="Are you looking for a speaker who can talk about success habits and gamifying life? Will Moore is the perfect fit. Book Will Moore for your event today!"
        link="https://mooremomentum.com/speaking/"
      />

      <div className="bg-blue mt-4">
        <div className="container mx-auto px-4">
          <h1 className="font-Orbitron py-4 text-2xl text-white sm:text-3xl lg:py-8 lg:text-4xl">
            Book Will Moore To Speak
          </h1>
        </div>
      </div>

      <div className="max-w-full mx-auto mt-16 px-4 lg:max-w-6xl">
        <OnScreen className="mb-16" classToAdd="slide-in-left">
          <div className="bg-blue max-w-3xl mx-auto p-4 rounded-xl shadow-lg text-center text-white md:p-8">
            <div className="wistia_responsive_padding relative iframe-wrapper">
              <div className="wistia_responsive_wrapper h-full left-0 absolute top-0 w-full">
                <div className="wistia_embed wistia_async_em8dai1k1z videoFoam=true h-full relative w-full">
                  <div className="wistia_swatch h-full left-0 opacity-1 overflow-hidden absolute top-0 transition-opacity width-full">
                    <img
                      src="https://fast.wistia.com/embed/medias/em8dai1k1z/swatch"
                      className="filter blur-sm h-full object-contain width-full"
                      alt="Will Moore Tedx Interview"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>

            <p className="font-bold mt-8">
              <em>
                &quot;Will Moore was one of our main speakers of the first
                online TEDx event. Will inspired, motivated and activated our
                attendees and our team in such a great way. It was such a
                pleasure to work with him, learn from him, and see him thrive on
                stage.&quot;
              </em>
              <br />- Linus Endacott - Tedx Chief Organizer
            </p>
          </div>
        </OnScreen>

        <div className="gap-4 grid grid-cols-1 items-center lg:gap-16 lg:grid-cols-2">
          <div className="mt-4 order-2 lg:mt-0 lg:order-1">
            <OnScreen classToAdd="scale-in-ver-center">
              <div className="space-y-4">
                <p>
                  {/* Will Moore is a serial entrepreneur, speaker, host, life
                  coach, and happiness expert. */}
                  Will Moore is a serial entrepreneur, habits, happiness, and
                  gamification expert.
                </p>

                <p>
                  {/* After exiting his delivery startup for $323 mil in 2019, he
                  made it his mission to solve what he sees as the #1 problem
                  facing humans today: Science and Tech are advancing faster
                  than our knowledge can responsibly manage, leading to a steady
                  decrease in happiness. */}
                  After{" "}
                  <a
                    href="https://table.skift.com/2018/12/12/waitr-acquires-bite-squad-for-321-million/"
                    target="_blank"
                    className="text-blue"
                  >
                    exiting his delivery startup for a combined $321 m in 2019
                  </a>{" "}
                  he’s now dedicated his life to solve what he sees as the #1
                  crisis to humanity; “Our knowledge is advancing faster than
                  our wisdom leading us to develop more and more failure habits
                  that are eviscerating our momentum and happiness.
                </p>

                <p>
                  {/* To combat this, Will is gamifying happiness building by using
                  that same science and tech to make it fun and addictive for a
                  person to incrementally improve in what he&apos;s determined
                  are the building blocks for happiness: The 5 Core areas of our
                  life we all share. */}
                  Will’s unique method combines universal principles, behavioral
                  science, and technology to make it fun and addictive to{" "}
                  <Link to="/gamify-your-life" className="text-blue">
                    gamify your life
                  </Link>
                  . His system helps you trick your brain to make what you
                  should do what you want to do - allowing you to replace your
                  failure habits with{" "}
                  <Link to="/success-habits" className="text-blue">
                    success habits
                  </Link>{" "}
                  in the{" "}
                  <Link to="/5-core-areas-of-life/" className="text-blue">
                    five core areas of happiness.
                  </Link>
                </p>

                <p>
                  {/* His company, Moore Momentum, vows to always put his
                  &apos;users&apos; wellness over profit so that when they level
                  up on screen, they&apos;ll also level up in real life. Are you
                  ready to fire on all cylinders? */}
                  He helps entrepreneurs, students, and businesses figure out
                  what it means to live a balanced life full of continual growth
                  where positive well-being always comes before profits. His
                  hope is that as he helps you gain momentum, you’ll then pay it
                  forward to help the world build some serious momentum of its
                  own.
                </p>
              </div>
            </OnScreen>
          </div>

          <div className="order-1 text-center lg:order-2">
            <OnScreen
              className="bg-yellow max-w-md mx-auto pr-8 pt-8 red-shadow rounded-md shadow-lg xl:max-w-xl"
              classToAdd="slide-in-right"
            >
              <StaticImage
                alt="Will Moore"
                className="flip"
                placeholder="blurred"
                src="../images/hero.png"
              />
            </OnScreen>
          </div>
        </div>

        <div className="max-w-6xl my-16 mx-auto px-4 lg:my-24">
          <h2 className="font-Orbitron mb-8 text-center text-3xl">
            As Seen On
          </h2>

          <div className="flex flex-wrap items-center sm:flex-nowrap sm:space-x-8 lg:space-x-16">
            <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
              <StaticImage
                alt="Tedx Logo"
                className="w-32 sm:w-auto"
                src="../images/tedx.png"
              />
            </div>

            <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
              <StaticImage
                alt="ABC Logo"
                className="h-24 w-32 sm:w-auto"
                objectFit="contain"
                src="../images/abc.png"
              />
            </div>

            <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
              <StaticImage
                alt="NBC Logo"
                className="w-32 sm:w-auto"
                src="../images/nbc.png"
              />
            </div>

            <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
              <StaticImage
                alt="Entrepreneur Logo"
                className="w-32 sm:w-auto"
                src="../images/entrepreneur.png"
              />
            </div>

            <div className="text-center w-1/2 sm:w-auto">
              <StaticImage
                alt="OBJ Logo"
                className="w-24 sm:w-auto"
                src="../images/obj.png"
              />
            </div>

            <div className="text-center w-1/2 sm:w-auto">
              <StaticImage
                alt="CW Logo"
                className="w-32 sm:w-auto"
                src="../images/cw.png"
              />
            </div>
          </div>
        </div>

        <div className="gap-8 grid grid-cols items-center mt-16 sm:grid-cols-2">
          <div className="px-4">
            <OnScreen classToAdd="slide-in-left">
              <div className="bg-white max-w-lg mx-auto p-4 relative rounded-md shadow-lg">
                <div className="absolute bg-blue h-24 -left-4 -top-4 w-24 -z-1" />
                <div className="absolute bg-blue -bottom-4 h-24 -right-4 w-24 -z-1" />

                <StaticImage
                  alt="Will Moore Speaking"
                  placeholder="blurred"
                  src="../images/speaking.png"
                />
              </div>
            </OnScreen>
          </div>

          <div>
            <OnScreen classToAdd="slide-in-right">
              <div className="space-y-4">
                <h2 className="font-Orbitron mb-8 text-2xl lg:text-4xl">
                  Will&apos;s Topics Include...
                </h2>

                <ul className="topics-list pl-4">
                  <li className="mt-4">
                    {/* Transforming Your Habits Through Gamification To Reduce The
                    Friction Of Leveling Up Your Life. */}
                    <Link to="/gamify-your-life" className="text-blue">
                      Gamify your habits
                    </Link>{" "}
                    by combining universal principles, behavioral science, and
                    technology to make it fun and addictive to level-up not just
                    on screen, but in REAL LIFE!
                  </li>
                  <li className="mt-4">
                    {/* Combining Universal Principles With The Latest In Science
                    And Tech To Combat The &quot;Social Dilemma.&quot; */}
                    Create the optimal work/life balance through an
                    understanding of the main areas of life scientifically
                    linked to happiness, your{" "}
                    <Link to="/5-core-areas-of-life/" className="text-blue">
                      Five Cores
                    </Link>
                    , and how to gamify your growth in each
                  </li>
                  <li className="mt-4">
                    {/* &quot;Reparenting&quot; Adults In A Modern World To Redefine
                    And Provide A System For Happiness. */}
                    Learn how to use the three fundamentals for change -
                    awareness, accountability, and automaticity to trick your
                    brain to WANT to take the actions to replace your failure
                    habits with{" "}
                    <Link to="/success-habits" className="text-blue">
                      success habits
                    </Link>
                    .
                  </li>
                </ul>
              </div>
            </OnScreen>
          </div>
        </div>
      </div>

      <h2 className="font-Orbitron mb-8 mt-16 text-center text-3xl lg:mt-24 lg:text-4xl">
        Testimonials
      </h2>

      <div className="max-w-5xl mx-auto mb-16 mt-8 px-4 lg:mb-24">
        <div className="space-y-8">{getTestimonials()}</div>
      </div>

      <div className="bg-gray py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="font-Orbitron mb-4 text-2xl md:text-3xl">
            Book Will for Your Next Event!
          </h2>

          <p className="mb-8">
            Please provide as much detail as possible and we&apos;ll be in touch
            ASAP!
          </p>

          <form
            name="contact"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />

            <div className="space-y-8">
              <div className="gap-8 grid sm:grid-cols-2">
                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                    name="first-name"
                    placeholder="First Name"
                    required
                    type="text"
                  />
                </div>

                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                    name="last-name"
                    placeholder="Last Name"
                    required
                    type="text"
                  />
                </div>
              </div>

              <div className="gap-8 grid sm:grid-cols-2">
                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                    name="email-address"
                    placeholder="Email Address"
                    required
                    type="email"
                  />
                </div>

                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                    name="phone-number"
                    placeholder="Phone Number"
                    required
                    type="tel"
                  />
                </div>
              </div>

              <div>
                <textarea
                  className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                  name="message"
                  placeholder="Provide your message here..."
                  rows={8}
                />
              </div>

              <Button className="px-12" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulSuccessStory(
      filter: { name: { in: ["Mark Hansen", "Mitzi Perdue", "Micki Meyer"] } }
    ) {
      edges {
        node {
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          id
          name
          testimonial {
            testimonial
          }
          title
        }
      }
    }
  }
`

export default Speaking
